import halfpic1 from "../../assets/img/ian-schneider-TamMbr4okv4-unsplash.jpg";
import halfpic2 from "../../assets/img/marvin-meyer-SYTO3xs06fU-unsplash.jpg";

export const Halfnhalf = () => {
    return (
        <section className="c-halfnhalf u-flex u-flex-col"
        // style={{ backgroundImage: `url(${halfpic1})` }}
        >
            <span id="contentScroll"></span>

            <section className="u-flex u-fullwidth u-flex-wrap">
                <section className="o-company">
                    <div className="o-text-grid--left">
                        <h2>La Nostra Agenzia</h2>
                        <p>L’Agenzia <strong>Comunian e Zanetti Assicuratori SNC di Comunian Matteo e Zanetti Andrea</strong> propone soluzioni personalizzate per ogni esigenza assicurativa personale, professionale e aziendale. Offriamo una gamma completa ed innovativa di soluzioni per la persona, la famiglia, la professione e l’azienda.
                        </p>
                        <p>Rivolgersi alla nostra Agenzia vuol dire sicurezza di trovare soluzioni e servizi al passo coi tempi, persone competenti su cui contare.</p>
                    </div>
                </section>
                <section className="o-halfnhalf-pic" style={{ backgroundImage: `url(${halfpic1})` }} ></section>
            </section>
            <section className="u-flex u-fullwidth u-flex-wrap-reverse">
                <section className="o-halfnhalf-pic" style={{ backgroundImage: `url(${halfpic2})` }} ></section>
                <section className="o-clients">
                    <div className="o-text-grid--right">
                        <h2>Il cliente prima di tutto</h2>
                        <p>Noi consulenti assicurativi sappiamo quanto sia importante avere un dialogo con i nostri Clienti: solo ascoltando realmente le loro esigenze siamo in grado di identificare le soluzioni assicurative che tutelano al meglio tutto ciò che a loro stà più a cuore.</p>
                        <p>Oggi è molto facile trovare soluzioni di qualsiasi tipo nel mercato assicurativo, i prodotti proposti sono tra i più disparati e vari ma l’efficienza di una protezione assicurativa si scopre solamente nel momento del bisogno.<br />
                        Effettuare un errore nella fase di valutazione e di acquisto è come scoprire che l’estintore non funziona durante un incendio.</p>
                        <p>Per questo è fondamentale nel momento della scelta potersi rivolgere a un professionista di cui ci fidiamo e che ci sappia indirizzare verso l’opzione più adatta a noi.</p>
                    </div>
                </section>
            </section>
        </section>
    )
}