import { SEO } from "../../../SEO";

import { PageHero } from "../PageHero";

export const Alberghi = ({ pageName }) => {
    return (
        <>
            <SEO
                title="Comunian & Zanetti - Alberghi"
                description="Tuteliamo le imprese del settore, l'attività, la proprietà e il patrimonio dell'albergo, senza lasciare nulla al caso"
            />

            <PageHero pageName={pageName} pageTitle="alberghi e strutture ricettive" />

            <section className="o-page-content u-flex u-flex-col">
                <section className="o-content-paragraph u-flex u-flex-col">
                    <p><strong>Tuteliamo</strong> le imprese del settore, l'attività, la proprietà e il patrimonio dell'albergo, <strong>con coperture</strong> per la responsabilità civile e in caso di: furto, incendio, danni alle apparecchiature elettroniche e ai cristalli. Insieme alle garanzie di base viene proposta una vasta serie di clausole opzionali per le problematiche più specifiche, per tutelarti da ogni evenienza che riguardi la tua attività, <strong>senza lasciare niente al caso.</strong></p>
                </section>
            </section>
        </>
    )
}