import { SEO } from "../../../SEO";

import { PageHero } from "../PageHero";

export const Deo = ({ pageName }) => {
    return (
        <>
            <SEO
                title="Comunian & Zanetti - Directors and Officers Liability"
                description="La polizza D&O (Directors & Officers Liability) è la soluzione assicurativa che assolve il compito di proteggere il patrimonio personale degli amministratori e dei membri degli altri organi di gestione (amministratori, direttori, dirigenti, sindaci ed eventuali membri del consiglio di sorveglianza) nei casi in cui vengono chiamati in causa per risarcimento danni."
            />

            <PageHero pageName={pageName} pageTitle="directors & officers liability" />

            <section className="o-page-content u-flex u-flex-col">
                <section className="o-content-paragraph u-flex u-flex-col">
                    <p>La <strong>polizza D&O (“Directors & Officers Liability”)</strong> è la <strong>soluzione assicurativa</strong> che assolve il compito di proteggere il patrimonio personale degli amministratori e dei membri degli altri organi di gestione (amministratori, direttori, dirigenti, sindaci ed eventuali membri del consiglio di sorveglianza) nei casi in cui vengono chiamati in causa per risarcimento danni.
                
                    <h2>Chi contrae la polizza e chi copre?</h2>
                    La polizza D&O è contratta dall'azienda ed assicura: 
                    <ul className="o-list">
                        <li>il Presidente ed il Vice-Presidente, </li>
                        <li>i Consiglieri d'Amministrazione </li>
                        <li>gli Amministratori di fatto </li>
                        <li>i Dirigenti ed i Sindaci </li>
                        <li>i membri del Consiglio di Sorveglianza, </li>
                    </ul>
                    garantendo dunque questi soggetti dalle azioni di terzi, che possono aggredire direttamente il loro patrimonio personale.
                    <br/><br/>
                    Sono tutelati anche i Membri dell'Organismo speciale di vigilanza (D.Lgs. 231/01), il Responsabile della sicurezza (D.Lgs. 81/08), il Responsabile del trattamento dei dati personali (D.Lgs. 196/03),  il Data Protection Officer (Reg Europeo UE /2016 /796), il membro dell'internal audit, il Risk Manager e il Shadow - Director (l'Amministratore occulto).
                    <br/>
                    Viene inoltre tutelato ogni dipendente della Società che eserciti funzioni manageriali o di supervisione o rivesta una Carica Direttiva esterna, chi compie un atto dannoso relativo ad una funzione assicurata, ad esempio in relazione ad un sinistro di lavoro o nel quale il dipendente è co-imputato, o ancora per un sinistro nel quale si contesti a tale dipendente di essere responsabile quale amministratore di fatto di una Società. 
                    </p>
                </section>
            </section>
        </>
    )
}