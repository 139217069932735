import { SEO } from "../../../SEO";

import { PageHero } from "../PageHero";

export const Noleggiolungo = ({ pageName }) => {
    return (
        <>
            <SEO
                title="Comunian & Zanetti - Noleggio a lungo termine"
                description="Con il noleggio a lungo termine puoi avere un'auto nuova sempre a tua disposizione ma senza doverla acquistare. Le spese sono incluse nel canone mensile, che comprende tutti i servizi necessari come l'assicurazione e la manutenzione ordinaria e straordinaria."
            />

            <PageHero pageName={pageName} pageTitle="noleggio a lungo termine" />

            <section className="o-page-content u-flex u-flex-col">
                <section className="o-content-paragraph u-flex u-flex-col">
                    <h2>Il Noleggio Auto a prezzi imbattibili </h2>

                    <p>Con il <strong>noleggio a lungo termine</strong> puoi avere un'auto nuova sempre a tua disposizione ma senza doverla acquistare. Le spese sono incluse nel canone mensile, che comprende tutti i servizi necessari come l'assicurazione e la manutenzione ordinaria e straordinaria.<br/><br/>

                    Ogni giorno offerte speciali di automobili, tutte le marche: puoi scegliere quella che fa per te tra <strong>Pronta consegna, Maxi offerta, Offerta Shock, Tutto incluso</strong>.<br /><br />
                    
                    <strong>È la soluzione perfetta</strong> per coloro che amano cambiare spesso l'auto senza pensare alla svalutazione, alla sua manutenzione e alla eventuale rivendita. Rappresenta una formula alternativa all'acquisto di un'auto in grado di offrire all'utilizzatore numerosi vantaggi tra i quali la possibilità di inserire nel canone mensile tutti i servizi accessori come la manutenzione ordinaria e straordinaria, l'assicurazione contro il furto e incendio, il bollo, il cambio gomme, il soccorso stradale e altri servizi associati che possono essere inseriti al momento della stipula a scelta dell'utente.<br/>
                    Tra i vantaggi da non trascurare ci sono l'opportunità di sfruttare sconti e offerte altrimenti non riscontrabili nella classica procedura di acquisto, la possibilità di noleggiare un'auto ibrida o 100% elettrica così da poter transitare nei centro città senza problemi di restrizioni ambientali, la possibilità di scegliere auto sempre più connesse e di ultima tecnologia.</p>
                </section>
            </section>
        </>
    )
}