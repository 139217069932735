import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import * as serviceWorker from "./serviceWorker";
import reportWebVitals from './reportWebVitals';

import './assets/scss/fonts.scss';
import './assets/scss/main.scss';

const container = document.getElementById('root');

const root = createRoot(container);

root.render(
  <BrowserRouter>
    <React.StrictMode>
      <App tab="home" />
    </React.StrictMode>
  </BrowserRouter>,
);


serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();