import { Link } from "react-scroll";

import scrollDown from "../assets/svg/chevron-up-solid.svg";

export const BackToTop = ({ scrollHeight }) => {
    return (
        <section className={scrollHeight >= 100 ? "c-scroll-top" : "u-hidden"}>
            {/* This chevron is available for free at https://fontawesome.com/icons/chevron-up?style=solid
                #TODO SET SCROLLHEIGHT TO A VARIABLE
            */}
            <Link to="scrollTop" smooth={true} duration={500}>
                <img src={scrollDown} className="o-chevron" alt="Torna in cima" />
            </Link>
        </section>
    )
}