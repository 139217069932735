import React from 'react';
import { useState, useEffect } from "react";
import { Link } from "react-scroll";

import scrollDown from "../../assets/svg/chevron-up-solid.svg";
import prevArrow from "../../assets/svg/chevron_prev.svg";
import nextArrow from "../../assets/svg/chevron_next.svg";

import swiperBg1 from "../../assets/img/life-resort.jpg";
import swiperBg2 from "../../assets/img/assicurazioni-tutela-1.jpg";
import swiperBg3 from "../../assets/img/polizza-temporanea-morte.jpg";

import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
// Swiper styles
import 'swiper/scss';
//import 'swiper/css/navigation';
//import 'swiper/css/pagination';
import 'swiper/scss/autoplay';


export const Hero = React.memo(() => {
    const heroItems = [
        {background: swiperBg1, payload: "VIVI LA VITA SENZA PREOCCUPAZIONI"},
        {background: swiperBg2, payload: "DA OLTRE 60 ANNI CI PRENDIAMO CURA DI TE"},
        {background: swiperBg3, payload: "METTI AL SICURO CHI AMI DI PIÙ", linkTo: "/todo"}
    ],
          [swiperHeroItem, setSwiperHeroItem] = useState(heroItems)

    // init Swiper:
    const heroSwiper = new Swiper('.o-hero-swiper', {
        loop: true,
        modules: [ Navigation, Pagination, Autoplay ],
        speed: 1000,
        grabCursor: true,

        // Navigation arrows
        navigation: {
            nextEl: '.swiper-arrow--next',
            prevEl: '.swiper-arrow--prev',
        },

        // Pagination capsules
        pagination: {
            el: '.swiper-pagination-capsules',
            clickable: true,
        },

        autoplay: {
            delay: 3000,
            disableOnInteraction: false
        },
    });

    useEffect(() => {
        heroSwiper.init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    return (
        <section id="main" className="u-flex">

            <div className="swiper-pagination swiper-pagination-capsules"></div>
            
            <div className="swiper o-hero-swiper">
                <div className="swiper-wrapper o-hero-swiper-wrapper">
                    {
                        swiperHeroItem &&
                            swiperHeroItem.map((swiperItem, index) => (
                                <div key={"swiper-"+swiperItem.background} style={{ backgroundImage: `url(${swiperItem.background})` }} className="swiper-slide">
                                    {swiperItem.linkTo ? <a href={swiperItem.linkTo}><h1>{swiperItem.payload}</h1></a> : <h1>{swiperItem.payload}</h1>}
                                </div>
                            )
                        )
                    }
                </div>

                <div className="swiper-arrow swiper-arrow--next">
                    <img src={nextArrow} alt="Avanti" />
                </div>
                <div className="swiper-arrow swiper-arrow--prev">
                    <img src={prevArrow} alt="Indietro" />
                </div>
            </div>
            
            {/* SCROLL DOWN CHEVRON This chevron is available for free at https://fontawesome.com/icons/chevron-up?style=solid */}
            <Link to="contentScroll" smooth={true} duration={500} className="c-scroll-down">
                <img src={scrollDown} className="o-chevron" alt="Esplora il sito" />
            </Link>

        </section>
    );
})