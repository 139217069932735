import { pageNames } from './pageNames';

export const PageHero = ({ pageName, pageTitle }) => {
    pageNames[pageName] ? window[pageName] = pageName : pageName = "pageNotFound"

    return (
        <section className={"c-page-hero o-" + pageName + "-hero"} style={{ backgroundImage: `url(${pageNames[pageName]})` }}>
            <section className="o-section-title--sub">
                <h1>{pageTitle}</h1>
            </section>
        </section>
    )
}