import { SEO } from "../../../SEO";

import { PageHero } from "../PageHero";

export const Welfare = ({ pageName }) => {
    return (
        <>
        <SEO
            title="Comunian & Zanetti - Welfare"
            description="Offriamo soluzioni personalizzate per le Aziende in ambito di Welfare aziendale."
        />
            <PageHero pageName={pageName} pageTitle="welfare aziendale" />

            <section className="o-page-content u-flex u-flex-col">
                <section className="o-content-paragraph u-flex u-flex-col">
                    <p>Offriamo soluzioni <strong>personalizzate</strong> per le <strong>Aziende</strong> in ambito di Welfare aziendale: <br/><br/>
                    <ul className="o-list">
                        <li>Tutela a 360° dell'imprenditore, amministratori dell'azienda e uomini chiave (Keyman) </li>
                        <li>La collettività dei dipendenti </li>
                    </ul>  
                    <br/>In particolare, sono 3 i vantaggi principali che derivano dall'offrire ai lavoratori incentivi che migliorino la loro vita lavorativa e personale: miglioramento del clima aziendale; aumento della soddisfazione e del benessere dei dipendenti; vantaggi fiscali.
                    </p>
                </section>
            </section>
        </>
    )
}