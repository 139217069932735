export const menuList = [
  {
    title: 'Privati',
    url: '/',
    submenu: [
      {
        title: 'Mobilità',
        url: 'mobilita',
      },
      {
        title: 'Immobili',
        url: 'immobili',
      },
      {
        title: 'Professionisti',
        url: 'professionisti',
      },
      {
        title: 'Protezione',
        url: 'protezione',
      },
      {
        title: 'Risparmio',
        url: 'risparmio',
      },
    ],
  },
  {
    title: 'Business',
    url: '/',
    submenu: [
      {
        title: 'PMI',
        url: 'PMI',
      },
      // {
      //   title: 'Vantaggi del prodotto per le PMI',
      //   url: 'vantaggiPMI',
      // },
      {
        title: 'Industria',
        url: 'industria',
      },
      {
        title: 'Alberghi e strutture ricettive',
        url: 'alberghi',
      },
      {
        title: 'Rc Prodotti',
        url: 'rcprodotti',
      },
      {
        title: 'Cyber Risk',
        url: 'cyberrisk',
      },
      {
        title: 'D&O',
        url: 'deo',
      },
    ],
  },
  {
    title: 'Settore Costruzioni',
    url: '/',
    submenu: [
      {
        title: 'Impresa edile',
        url: 'impresaedile',
      },
      {
        title: 'Polizza car',
        url: 'polizzacar',
      },
      {
        title: 'Polizza postuma decennale',
        url: 'polizzapostuma',
      },
      {
        title: 'Cauzione (fidejussione)',
        url: 'cauzione',
      },
    ],
  },
  {
    title: 'Welfare Aziendale',
    url: '/',
    submenu: [
      {
        title: 'Polizze TFM - TFR',
        url: 'tfmtfr',
      },
      {
        title: 'Welfare',
        url: 'welfare',
      }
    ],
  },
  {
    title: 'Tutela Legale',
    url: 'tutelalegale',
  },
  /*
  {
    title: 'Contatti',
    url: 'contacts',
  }
  */
];