import { SEO } from "../../../SEO";

import { PageHero } from "../PageHero";

export const Protezione = ({ pageName }) => {
    return (
        <>
            <SEO
                title="Comunian & Zanetti - Protezione"
                description="Una soluzione assicurativa completa per tutelarti dagli infortuni sul lavoro (integrandomi le scoperture dell'INAIL), legati alla vita quotidiana o all'attività sportiva che causino decesso o perdita parziale o totale dell'integrità psicofisica, possiamo prevedere insieme a te una diaria per il periodo di degenza, convalescenza, immobilizzazione o totale inabilità al lavoro a seguito di infortunio."
            />

            <PageHero pageName={pageName} pageTitle="protezione" />

            <section className="o-page-content u-flex u-flex-col">
                <section className="o-content-paragraph u-flex u-flex-col">
                    <h3>COPERTURA ASSICURATIVA INFORTUNI professionale ed extra</h3>
                    <p>Una soluzione assicurativa completa per tutelarti dagli infortuni sul lavoro <strong>(integrandomi le scoperture dell'INAIL)</strong>, legati alla vita quotidiana o all'attività sportiva che causino decesso o perdita parziale o totale dell'integrità psicofisica, possiamo prevedere insieme a te una diaria per il periodo di degenza, convalescenza, immobilizzazione o totale inabilità al lavoro a seguito di infortunio.</p>
                    
                    <h3>INVALIDITÀ PERMANENTE DA MALATTIA</h3> 
                    <p><strong>Possiamo offrirti soluzioni PERSONALIZZATE per coprirti in caso di INVALIDITA' PERMANENTE DA MALATTIA </strong>(I.P.): la perdita o la diminuzione definitiva ed irrimediabile, a seguito di MALATTIA, della capacità di svolgere, in tutto o in parte, qualsiasi generica attività lavorativa, indipendentemente dalla sua professione. </p>   

                    <h3>RIMBORSO SPESE SANITARIE</h3>
                    <p><strong>Un'assicurazione sanitaria è una polizza che copre le spese sostenute nella sanità privata in caso di malattia o infortunio.</strong> È una copertura fondamentale visto il periodo storico che stiamo vivendo che viene scelta in tutti quei casi in cui si preferisca avere un'alternativa rispetto alla sanità pubblica, o per tutelarsi da eventuali imprevisti e problemi di salute in caso di viaggi.</p>   

                    <h3>LONG TERM CARE</h3>
                    <p>La soluzione che ti dà un'integrazione economica per tutta la vita in caso di perdita dell'autosufficienza.</p>

                    <h3>COPERTURA ASSICURATIVA VITA </h3>
                    <p><bold>Per mantenere sempre le promesse fatte.</bold><br/>
                    La casa, lo studio dei figli, l'auto: il benessere è bello, ma costa. Vivere bene è una promessa che facciamo ogni giorno a chi amiamo, ma è anche un impegno economico che richiede una gestione attenta delle disponibilità. <br/><br/>
                    Possiamo offrirti la migliore soluzione per tutelare il tuo nucleo famigliare dall'insicurezza che la vita comporta, garantendo un capitale in caso di prematura scomparsa della persona fonte del reddito principale.
                    </p>

                    <p></p>
                </section>
            </section>
        </>
    )
}
                        
                        
                        
                        
                        
                        
                        