import { SEO } from "../../../SEO";

import { PageHero } from "../PageHero";

export const Industria = ({ pageName }) => {
    return (
        <>
            <SEO
                title="Comunian & Zanetti - Industria"
                description="Il patrimonio aziendale, le risorse umane e strumentali: da imprenditore sai che le fondamenta della tua impresa vanno protette."
            />

            <PageHero pageName={pageName} pageTitle="industria" />

            <section className="o-page-content u-flex u-flex-col">
                <section className="o-content-paragraph u-flex u-flex-col">
                    <p>Il patrimonio aziendale, le risorse umane e strumentali: da imprenditore sai che le fondamenta della tua impresa vanno protette. Tramite la nostra consulenza affianchiamo la tua impresa a 360°, in fase di valutazione del rischio, nella costruzione della copertura più adatta su misura per le tue esigenze e soprattutto nella gestione degli eventuali sinistri. </p>
                
                    <h2>Danni materiali e diretti</h2>
                    <p>Assicuriamo i fabbricati, gli impianti, gli arredi, le attrezzature, i macchinari e le merci per i danni materiali e diretti a seguito per esempio di incendio, fulmine, esplosione, scoppio, fuoriuscita fluidi, eventi atmosferici, atti vandalici, catastrofi naturali, ecc..  </p>
                
                    <h2>Danni indiretti (fermo attività)</h2>
                    <p>Assicuriamo le perdite di carattere finanziario dovute alla riduzione, o fermo dell 'attività, conseguente ad eventi assicurati dalla copertura danni materiali e diretti per esempio se a seguito di un incendio viene distrutto il capannone, la polizza danni materiali e diretti indennizza la ricostruzione del fabbricato ed il riacquisto del suo contenuto; mentre la polizza danni indiretti risarcisce il danno economico derivante dal fermo attività per tutto il tempo necessario alla ripresa della produzione.</p>
                
                    <h2>Elettronica</h2>
                    <p>La polizza assicura le apparecchiature per l 'elaborazione e la trasmissione dei dati, i sistemi radiotelevisivi, le apparecchiature di laboratorio, i medicali, le apparecchiature di controllo e comando, nonché gli altri macchinari ed impianti ad alto contenuto di elettronica. 
                    <br/>
                    La copertura prevede l'indennizzo dei danni materiali alle apparecchiature assicurate dovute ad eventi accidentali quali guasti, corto circuito, furti, incendi, allagamenti, alluvioni ed altri eventi atmosferici;</p>

                    <h2>Furto, rapina, estorsione</h2>
                    <p>La polizza assicura le merci, le attrezzature, gli arredi ed i valori contro il furto, i guasti cagionati dai ladri, la rapina e l 'estorsione. È possibile estendere la polizza a garanzia dei portavalori (furto, rapina e scippo ai danni di persone incaricate dall 'azienda al trasporto di denaro e valori in genere fuori dei locali).</p>

                    <h2>Infedeltà dipendenti</h2>
                    <p>La polizza assicura l'appropriazione indebita, il furto, la rapina e la truffa perpetrate dai dipendenti.</p>

                    <h2>RCT - RCO </h2>
                    <p>La Polizza di Responsabilità Civile verso Terzi ed Operai prestatori di lavoro (RCT-RCO) è un contratto assicurativo che copre l'Assicurato di quanto egli sia tenuto a pagare, come civilmente responsabile ai sensi di legge, a titolo di risarcimento di danni involontariamente causati a terzi e subiti dai prestatori di lavori. 
                    <br/>
                    Mette al riparo l'imprenditore da eventuali, spesso dolorosissime rivalse INAIL!! </p>
                </section>
            </section>
        </>
    )
}