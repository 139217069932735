import { Link } from "react-router-dom";
export const Footer = ({ pageName }) => {
    // Function will execute on click of button
    const PDFDownload = (file) => {
        // using Java Script method to get PDF file
        fetch(file).then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = file;
                alink.click();
            })
        })
    }
    // 
    return (
        <section className={`c-footer u-flex u-flex-center ${pageName === "" && "o-home-variant"}`}>
            <Link to="/reclami"  className="o-footer-button">Reclami</Link>
            <button className="o-footer-button" onClick={() => PDFDownload('privacy_cookie_policy.pdf')}>Privacy &amp; Cookie</button>
            <button className="o-footer-button" onClick={() => PDFDownload('disclaimer.pdf')}>Disclaimer</button>
        </section>
    )
}