import { SEO } from "../../../SEO";

import { PageHero } from "../PageHero";

export const Impresaedile = ({ pageName }) => {
    return (
        <>
            <SEO
                title="Comunian & Zanetti - Impresa Edile"
                description="Attraverso un'attenta analisi dei rischi copriamo l'Impresa Edile dagli eventuali esborsi conseguenti ai danni causati involontariamente ad altre persone. La garanzia si attiva in caso di morte, di lesioni personali e di danneggiamenti a cose. "
            />

            <PageHero pageName={pageName} pageTitle="impresa edile" />
            
            <section className="o-page-content u-flex u-flex-col">
                <section className="o-content-paragraph u-flex u-flex-col">
                    <p>Attraverso un'attenta analisi dei rischi copriamo l'<strong>Impresa Edile</strong> dagli eventuali esborsi conseguenti ai danni causati involontariamente ad altre persone. La garanzia si attiva in caso di morte, di lesioni personali e di danneggiamenti a cose. 
                    E' fondamentale per l'imprenditore accedere al cantiere tutelato da un'<strong>Assicurazione Impresa Edile</strong> costruita su misura intorno alle reali esigenze della sua impresa e che prenda in considerazione tutti i rischi a cui lui, la sua ditta, i suoi dipendenti e i macchinari di lavoro sono costantemente esposti.
                    In caso di sinistro Andiamo a tutelare l'azienda a 360° sia in ambito civile che penale.
                    </p>
                </section>
            </section>
        </>
    )
}