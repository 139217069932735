import googleMap1 from "../../assets/img/mappa_agenzia.jpg";
import googleMap2 from "../../assets/img/mappa_subagenzia.jpg";

import { SEO } from "../../SEO";

import { PageHero } from "./PageHero";

export const Contacts = ({ pageName }) => {
    return (
        <>
            <SEO
                title="Comunian & Zanetti - Contatti"
                description="In questa pagina troverai le indicazioni di come trovarci alla nostra Agenzia Generale o alla nostra Subagenzia. Contattaci per ogni tua evenienza assicurativa."
            />

            <PageHero pageName={pageName} pageTitle="Contatti" />
        
            <section className="c-contacts o-page-content u-flex u-flex-col">
                <section className="u-flex u-fullwidth u-flex-wrap">
                    <section className="o-company">
                        <div className="o-text-grid--left">
                            <h2>Agenzia Generale</h2>
                            <p>Italiana Assicurazioni & Banca Reale<br />
                            Strada Battaglia, 129<br />
                            35020 ALBIGNASEGO (PD)<br />                            
                            <a href="tel:+0498808329">Tel. 049 - 8808329</a><br /><br />
                            Mail: <a href="mailto:info@comunianezanetti.it">info@comunianezanetti.it</a><br /></p>
                            <span><a href="https://telematici.agenziaentrate.gov.it/VerificaPIVA/">Partita IVA: </a> 05280470286</span><br />
                            <span><a href="https://servizi.ivass.it/RuirPubblica/">Numero RUI:</a> A000656299</span>
                        </div>
                    </section>
                    <a href="https://goo.gl/maps/heQ4NxUeVAPrPTxN7" target="_blank" className="o-halfnhalf-pic" rel="noreferrer">
                        <img src={googleMap1} loading="lazy" alt="Mappa dell'Agenzia Generale di Comunian e Zanetti" />
                    </a>
                </section>
                <section className="u-flex u-fullwidth u-flex-wrap-reverse">
                    <a href="https://goo.gl/maps/nbmTmg92f9vF5Tu68" target="_blank" className="o-halfnhalf-pic" rel="noreferrer">
                        <img src={googleMap2} loading="lazy" alt="Mappa della Subagenzia di Comunian e Zanetti" />
                    </a>
                    <section className="o-clients">
                        <div className="o-text-grid--right">
                            <h2>Subagenzia</h2>
                            <p>V. Armando Diaz, 146<br />
                            35010 - VIGONZA (PD)<br />
                            <a href="tel:+0498005177">Tel. 049 - 8005177</a><br /><br />
                            <span>Mail: </span><a href="mailto:ageitaliana.vigonza@gmail.com">ageitaliana.vigonza@gmail.com</a></p>
                        </div>
                    </section>
                </section>
            </section>
        </>
    )
}