import { SEO } from "../../../SEO";

import { PageHero } from "../PageHero";

export const Mobilita = ({ pageName }) => {
    return (
        <>
            <SEO
                title="Comunian & Zanetti - Mobilità"
                description="L'assicurazione obbligatoria Rcauto risulta essere uno dei prodotti più complessi del mercato Assicurativo; una consulenza adeguata risulta fondamentale per non avere poi in caso di sinistro spiacevoli o drammatiche sorprese!!"
            />

            <PageHero pageName={pageName} pageTitle="mobilità" />

            <section className="o-page-content u-flex u-flex-col">
                <section className="o-content-paragraph u-flex u-flex-col">
                    <h2>RC AUTO</h2>
                    <p>L'assicurazione obbligatoria Rcauto risulta essere uno dei prodotti più complessi del mercato Assicurativo; una consulenza adeguata risulta fondamentale per non avere poi in caso di sinistro spiacevoli o drammatiche sorprese!!<br/><br/>
                       <ul className="o-list">
                            <li><strong>ASSICURAZIONE AUTO</strong></li>
                            <li><strong>ASSICURAZIONE MOTO</strong></li>
                            <li><strong>ASSICURAZIONI FLOTTE</strong></li> 
                            <li><strong>ASSICURAZIONE ALTRI VEICOLI</strong></li> 
                            <li><strong>ASSICURAZIONE VIAGGI</strong></li>
                            <li><strong>ASSICURAZIONE NAUTICA</strong></li> 
                        </ul> 
                    </p>
                </section>
            </section>
        </>
    )
}