import { SEO } from "../../../SEO";

import { PageHero } from "../PageHero";

export const Polizzapostuma = ({ pageName }) => {
    return (
        <>
            <SEO
                title="Comunian & Zanetti - Polizza Postuma"
                description="La Decennale Postuma è un prodotto assicurativo rivolto a tutte le aziende che debbano assicurare l'immobile realizzato per i successivi dieci anni a partire dal termine dei lavori, per i danni materiali e diretti causati da rovina totale o parziale dell'immobile stesso, o da gravi difetti costruttivi."
            />

            <PageHero pageName={pageName} pageTitle="polizza postuma decennale" />

            <section className="o-page-content u-flex u-flex-col">
                <section className="o-content-paragraph u-flex u-flex-col">
                    <p><strong>La Decennale Postuma</strong> è un prodotto assicurativo rivolto a tutte le aziende che debbano assicurare l'immobile realizzato per i successivi dieci anni a partire dal termine dei lavori, per i danni materiali e diretti causati da rovina totale o parziale dell'immobile stesso, o da gravi difetti costruttivi.</p>
                </section>
            </section>
        </>
    )
}