import { Link } from 'react-router-dom';

import { Hero } from '../components/home/Hero';
import { Halfnhalf } from '../components/home/Halfnhalf';
import { Team } from '../components/home/Team';
import { Partners } from '../components/home/Partners';

export const Home = () => {
    return (
        <>
            <Hero />
            <Halfnhalf />
            <Team />
            <Partners />
        </>
    );
};