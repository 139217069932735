import { SEO } from "../../../SEO";

import { PageHero } from "../PageHero";

export const Servizibancari = ({ pageName }) => {
    return (
        <>
            <SEO
                title="Comunian & Zanetti - Servizi bancari"
                description="Grazie alla collaborazione tra Italiana Assicurazioni e Banca Reale possiamo offrire tutto ciò che serve per la serenità dei nostri clienti."
            />

            <PageHero pageName={pageName} pageTitle="servizi bancari" />

            <section className="o-page-content u-flex u-flex-col">
                <section className="o-content-paragraph u-flex u-flex-col">
                    <h1>Fiducia, esperienza e Partners di esperienza</h1>

                    <p>Grazie alla collaborazione tra Italiana Assicurazioni e Banca Reale possiamo offrire tutto ciò che serve per la serenità dei nostri clienti:</p>
                    
                    <ul className="o-list">
                        <li>Rateizzazione polizze assicurative</li>
                        <li>Conti correnti bancari</li>
                        <li>Finanziamenti per Condomini</li>
                        <li>Mutui e surroghe in abbinamento alla copertura assicurativa Casa</li>
                        <li>Fidi e finanziamenti per Aziende</li>
                        <li>Finanziamento polizze assicurative per Aziende</li>
                    </ul>
                </section>
            </section>
        </>
    )
}