import mobilitaBg from '../../assets/img/mobilita.jpg';
import immobiliBg from '../../assets/img/polizza-casa.jpg';
import professionistiBg from '../../assets/img/infortuni-lavoro.jpg';
import protezioneBg from '../../assets/img/protezione.jpg';
import risparmioBg from '../../assets/img/risparmio.jpg';

import pmiBg from '../../assets/img/grandine.jpg';
//import vantaggipmiBg from '../../assets/img/vantaggi-pmi.jpg';
import industriaBg from '../../assets/img/incendio-industria.jpg';
import alberghiBg from '../../assets/img/hotels.jpg';
import rcprodottiBg from '../../assets/img/rc-prodotti.jpg';
import cyberriskBg from '../../assets/img/cyber-risk.jpg';
import deoBg from '../../assets/img/director-and-officer.jpg';

import impresaedileBg from '../../assets/img/impresa-edile.jpg';
import polizzacarBg from '../../assets/img/polizza-car.jpg';
import polizzapostumaBg from '../../assets/img/polizza-postuma.jpg';
import cauzioneBg from '../../assets/img/cauzione.jpg';

import tfmtfrBg from '../../assets/img/tfr.jpg';
import welfareBg from '../../assets/img/welfare.jpg';

import tutelalegaleBg from '../../assets/img/tutela-legale.jpg';

import reclamiBg from '../../assets/img/reclami.jpg';
import contactsBg from '../../assets/img/eric-rothermel-FoKO4DpXamQ-unsplash.jpg';

import bluassistanceBg from '../../assets/img/direttore-sanitario.jpg';
import noleggioBg from '../../assets/img/noleggio-auto.jpg';
import gestionepatrimonioBg from '../../assets/img/gestione-patrimonio.jpg';
import servizibancariBg from '../../assets/img/servizi-bancari.jpg';

import pageNotFoundBg from '../../assets/img/ruben-bagues-vPQfc4niDh0-unsplash.jpg';


export const pageNames = {
    mobilita: mobilitaBg,
    immobili: immobiliBg,
    professionisti: professionistiBg,
    protezione: protezioneBg,
    risparmio: risparmioBg,
    
    PMI: pmiBg,
    //vantaggiPMI: vantaggipmiBg,
    industria: industriaBg,
    alberghi: alberghiBg,
    rcprodotti: rcprodottiBg,
    cyberrisk: cyberriskBg,
    deo: deoBg,
    
    impresaedile: impresaedileBg,
    polizzacar: polizzacarBg,
    polizzapostuma: polizzapostumaBg,
    cauzione: cauzioneBg,
        
    tfmtfr: tfmtfrBg,
    welfare: welfareBg,

    tutelalegale: tutelalegaleBg,
    
    reclami: reclamiBg,
    contacts: contactsBg,

    bluassistance: bluassistanceBg,
    noleggiolungo: noleggioBg,
    gestionepatrimonio: gestionepatrimonioBg,
    servizibancari: servizibancariBg,

    pageNotFound: pageNotFoundBg,
};