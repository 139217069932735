import { SEO } from "../../../SEO";

import { PageHero } from "../PageHero";

export const Cyberrisk = ({ pageName }) => {
    return (
        <>
            <SEO
                title="Comunian & Zanetti - Cyber Risk"
                description="L'importanza di preservare la sicurezza informatica della tua azienda passa attraverso una polizza cyber risk"
            />

            <PageHero pageName={pageName} pageTitle="cyber risk" />

            <section className="o-page-content u-flex u-flex-col">
                <section className="o-content-paragraph u-flex u-flex-col">
                    <p>L'importanza di <strong>preservare la sicurezza informatica</strong> della tua azienda passa attraverso una <strong>polizza cyber risk</strong>. In un mondo del lavoro sempre più connesso e digitale, preservare il tuo business da potenziali <strong>nuovi pericoli informatici</strong> è alla base per lavorare con la tranquillità necessaria a svolgere bene la propria attività. Se vuoi mettere al sicuro i dati e la produttività della tua impresa dal rischio informatico, esistono polizze dedicate che rispecchiano questo obiettivo. 
                        <br /><br />
                        La <strong>trasformazione digitale</strong> sta portando molti benefici a società, imprese, studi professionali, ma allo stesso tempo l'uso incontrollato dei mezzi digitali, della tecnologia e della rete, può comportare un rischio enorme definito “cyber risk” o “rischio informatico”, che può portare attacchi informatici sfruttando il patrimonio aziendale dei dati digitali, con conseguenze dannose.  
                        <br /><br />
                        Per tutti coloro che entrano in contatto con il mondo digitale, esiste una protezione ad hoc: l'assicurazione cyber risk adatta a tutti, artigiani, professionisti, imprenditori, gestori di bar e ristoranti e imprese private.  
                        <br /><br />
                        La gestione di quanto accennato sopra, può essere svolta in <strong>tre passi:</strong>
                        <ul className="o-list">
                            <li><strong>Verificare il livello di sicurezza </strong>della struttura informatica (ottenibile grazie agli antivirus e ai firewall), tramite degli stress test, dei penetration test e/o altre attività;</li>
                            <li><strong>Aggiornare i supporti </strong>hardware e software e formare il proprio personale.</li>
                            <li><strong>Identificare le polizze assicurative </strong>per coprire il rischio residuo e gestibile solo esternamente.</li>
                        </ul>  
                        <br />
                        La gestione di queste minacce è fondamentale per ogni organizzazione, ente o azienda, ma anche per singoli professionisti che <strong>gestiscono la propria attività attraverso processi interni digitalizzati</strong> (fatturazione, produzione, distribuzione), <strong>raccolgono informazioni sui clienti</strong>, <strong>detengono un database di informazioni personali</strong> e/o <strong>memorizzano le informazioni</strong> dei dipendenti digitalmente. 
                    </p>
                </section>
            </section>
        </>
    )
}
                    
                    
                    