import { SEO } from "../../../SEO";

import { PageHero } from "../PageHero";

export const Professionisti = ({ pageName }) => {
    return (
        <>
            <SEO
                title="Comunian & Zanetti - Professionisti"
                description="L'Assicurazione RC Professionale è la Polizza Assicurativa multirischio volta alla tutela della Responsabilità Civile dell'Assicurato, indennizzandolo da eventuali richieste di risarcimento avanzate nei suoi riguardi, per danni che dovesse colposamente cagionare a terzi, in relazione allo svolgimento della propria attività lavorativa."
            />

            <PageHero pageName={pageName} pageTitle="professionisti" />
            
            <section className="o-page-content u-flex u-flex-col">
                <section className="o-content-paragraph u-flex u-flex-col">
                    <h2>OBBLIGATORIET&Agrave;</h2>
                    <p>L'Assicurazione RC Professionale è la Polizza Assicurativa multirischio volta alla tutela della Responsabilità Civile dell'Assicurato, indennizzandolo da eventuali richieste di risarcimento avanzate nei suoi riguardi, per danni che dovesse colposamente cagionare a terzi, in relazione allo svolgimento della propria attività lavorativa.<br/>
                        A sancirne l'obbligatorietà è il Decreto Legge 13 agosto 2011 convertito con modificazioni alla L. 14 settembre 2011 (Bosetti e Gatti) che, all'Art. 3 comma 4 lettera e, recita:<br/>
                        "a tutela del cliente, il professionista è tenuto a stipulare idonea assicurazione per i rischi derivanti dall'esercizio dell'attività professionale. Il professionista deve rendere noti al cliente, al momento dell'assunzione dell'incarico, gli estremi della polizza stipulata per la responsabilità professionale e il relativo massimale." <br/><br/>
                        L'obbligo di stipula del Contratto Assicurativo viene introdotto attraverso quanto disposto dall'articolo 5 del D.P.R. 137/2012 che, con decorrenza 13 agosto 2013, vincola le diverse categorie professionali interessate all'adeguamento richiesto. <br/><br/>
                        Per quali professionisti risulta essere obbligatoria la stipula di una Polizza RC Professionale? <br/>
                        L'RC Professionale, come appena specificato, risulta essere obbligatoria per tutti i coloro i quali risultino essere iscritti al relativo Albo Professionale ad esempio: 
                        <ul className="o-list">
                            <li>Avvocati</li>
                            <li>Commercialisti</li>
                            <li>Architetti</li>
                            <li>Geometri</li>
                            <li>Ingegneri</li>
                            <li>Medici</li>
                        </ul>  
                        <br/>La polizza Rc Professionale va costruita su misura per ogni singolo professionista scegliendo sul mercato assicurativo la soluzione e il prodotto più consono. 
                    </p>
                    <p></p>
                </section>
            </section>
        </>
    )
}
                        
                        
                        
                        
                        
                        
                        