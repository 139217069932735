import { SEO } from "../../../SEO";

import { PageHero } from "../PageHero";

export const Rcprodotti = ({ pageName }) => {
    return (
        <>
            <SEO
                title="Comunian & Zanetti - RC Prodotti"
                description="La polizza RC Prodotti è una copertura assicurativa essenziale per molte aziende, in quanto tutela nel caso di eventuali danni a persone e cose derivanti da difetti del prodotto."
            />

            <PageHero pageName={pageName} pageTitle="rc prodotti" />
            
            <section className="o-page-content u-flex u-flex-col">
                <section className="o-content-paragraph u-flex u-flex-col">
                    <p>La <strong>polizza RC Prodotti</strong> è una copertura assicurativa essenziale per molte aziende, in quanto tutela nel caso di eventuali danni a persone e cose derivanti da difetti del prodotto. <br/>
                        Il produttore è infatti ritenuto responsabile sia dei danni causati da difetti nel prodotto originatesi in fase di progettazione o fabbricazione, sia dei danni conseguenti a difetti manifestatisi durante l 'uso del prodotto. <br/>
                        Il Codice del Consumo - Decreto Legislativo 206/2005 che recepisce in Italia la Direttiva Comunitaria n. 374/1985 definisce un prodotto difettoso quando "non offre la sicurezza che ci si può legittimamente attendere da questo”. L 'ampio concetto di "sicurezza" imputa al produttore un carico considerevole di possibili danni che possono colpire l 'integrità fisica del consumatore o i suoi beni. <br/>
                        Per questo, è fondamentale sottoscrivere una polizza di <strong>Responsabilità Civile Prodotti</strong> e allegare agli articoli informazioni e istruzioni d'uso pienamente comprensibili da qualunque utilizzatore, per prevenire possibili fraintendimenti che, per la direttiva 374/85, porterebbero già ad una responsabilità del produttore. <br/>
                        Stipulando un contratto assicurativo, il produttore trasferisce, del tutto o in parte, il rischio economico derivante dalla produzione, importazione o vendita di prodotti difettosi e garantisce al danneggiato il risarcimento che gli spetta. <br/>                        
                    </p>
                    <h2>Cosa copre?</h2>
                    <p><strong>L 'assicurazione di Responsabilità Civile da prodotto difettoso</strong> rientra tra le polizze <strong>RC Terzi</strong> e, in quanto tale, obbliga la compagnia assicurativa a risarcire i danni cagionati involontariamente a soggetti terzi, cioè diversi dall'assicurato, tra i quali i clienti utilizzatori. <br/>                    <br/>                    
                        I danni risarciti dalla polizza RC prodotti sono:
                        <ul className="o-list">
                            <li><strong>Danni corporali a terzi. </strong></li>
                            <li><strong>Danni materiali a cose distinte dal prodotto difettoso. </strong></li>
                        </ul>  
                        <br/>
                        Queste polizze assicurative escludono solitamente i danni subiti dall'azienda causati da difetti dei propri prodotti, come la riparazione e la sostituzione della merce, ma è possibile sottoscrivere apposite estensioni per coprire anche i costi sostenuti per il ritiro dal mercato dei prodotti difettosi, per le spese di smontaggio e rimontaggio o per il ritiro dei prodotti complessi di cui era entrato a far parte il prodotto fabbricato.
                    </p>
                </section>
            </section>
        </>
    )
}