import { SEO } from "../../../SEO";

import { PageHero } from "../PageHero";

export const Tutelalegale = ({ pageName }) => {
    return (
        <>
            <SEO
                title="Comunian & Zanetti - Tutela Legale"
                description="I problemi legali non sono tutti uguali, tramite le compagnie n° 1 del settore possiamo garantire ai nostri clienti il pagamento delle spese legali e peritali per ottenere il risarcimento del danno, l'assistenza legale per la presentazione di ricorsi, la DIFESA PENALE in caso di sinistro!"
            />

            <PageHero pageName={pageName} pageTitle="tutela legale" />
            
            <section className="o-page-content u-flex u-flex-col">
                <section className="o-content-paragraph u-flex u-flex-col">
                    <p><strong>I problemi legali non sono tutti uguali, tramite le compagnie n° 1 del settore possiamo garantire ai nostri clienti</strong> il pagamento delle spese legali e peritali per ottenere il risarcimento del danno, l'assistenza legale per la presentazione di ricorsi, la DIFESA PENALE in caso di sinistro!<br/><br/>
                        Ogni polizza di Responsabilità civile, in qualsiasi campo essa viene applicata necessita dell'affiancamento di una copertura di Difesa Legale Indipendente!<br/><br/>
                        <ul className="o-list">
                            <li>TUTELA LEGALE CIRCOLAZIONE STRADALE </li>
                            <li>TUTELA LEGALE RITIRO PATENTE </li>
                            <li>TUTELA LEGALE FAMIGLIA - VITA PRIVATA </li>
                            <li>TUTELA LEGALE PROFESSIONALE </li>
                            <li>TUTELA LEGALE AZIENDALE</li>
                        </ul>  
                    </p>
                </section>
            </section>
        </>
    )
}