import { SEO } from "../../../SEO";

import { PageHero } from "../PageHero";

export const Immobili = ({ pageName }) => {
    return (
        <>
            <SEO
                title="Comunian & Zanetti - Immobili"
                description="Proteggere la tua casa, i beni e gli arredi che si trovano al suo interno è per noi una grande responsabilità."
            />

            <PageHero pageName={pageName} pageTitle="immobili" />
            
            <section className="o-page-content u-flex u-flex-col">
                <section className="o-content-paragraph u-flex u-flex-col">
                    <h2>POLIZZA CASA</h2>
                    <p><bold>Proteggere</bold> la tua casa, i beni e gli arredi che si trovano al suo interno è per noi una grande responsabilità.  Disponiamo delle soluzioni ideali, che proteggono la tua famiglia anche all'esterno dell'abitazione, nel tempo libero e in vacanza.<br/><br/>
                    Possiamo costruire insieme a te la soluzione su misura più adatta con le difese di cui hai bisogno. <br/><br/>
                    Perche scegliere noi?<br/>
                    Perché ti consentiamo di costruire la tua personale sicurezza, scegliendo il numero e la tipologia di garanzie di cui hai bisogno, nulla di standardizzato!! <br/><br/>
                    Puoi metterti al riparo con una cifra ragionevole dai vari eventi che possono colpire il tuo immobile come ad esempio un incendio, un evento atmosferico, un atto vandalico, un danno da acqua, un furto ecc... <br/><br/>
                    Perché se hai un amico a quattro zampe che abita con te, ti tutela dai danni che potrebbe involontariamente procurare ad altre persone o alle loro cose. <br/><br/>
                    Perchè se qualcuno si occupa della tua casa, ti tutela nel caso in cui dovesse arrecare danni ad altre persone e alle loro cose, o qualora dovesse farsi male nello svolgimento delle sue mansioni. </p>
                    <h2>POLIZZA RC ANIMALE DOMESTICO</h2>
                    <p>Perché se hai un amico a quattro zampe che abita con te, ti tutela dai danni che potrebbe involontariamente procurare ad altre persone o alle loro cose, oppure potrebbe lui stesso aver bisogno di cure a seguito di infortunio o malattia. </p>
                    <h2>POLIZZA CONDOMINIO</h2>
                    <p>Come rimediare alla devastazione di un incendio? Oppure ai danni di una manifestazione che ha preso una piega sbagliata? Basti pensare ad esempio a quei palazzi situati al centro di grandi città, prese d'assalto da manifestazioni di ogni tipo; fino a questo momento i condomini dovevano accollarsi tutte le spese per le varie riparazioni.<br/>
                    La polizza è così chiamata perché offre una <strong>copertura sull'intero stabile.</strong><br/>
                    Con la riforma del Condominio, ogni Amministratore, previa autorizzazione dell'Assemblea, potrà stipulare una polizza grazie alla quale potranno essere trasferiti i rischi di danni che vanno dall'incendio alla responsabilità civile, ai danni da acqua e da eventi sociopolitici. </p>
                </section>
            </section>
        </>
    )
}