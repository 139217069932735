import { useState } from 'react';
import { Link } from 'react-router-dom';

import { extraMenuList } from './extraMenuList';

export const Extra = ({ pageHeroHeight, scrollHeight, viewportHeight }) => {
    const [extraMenuListItem, setExtraMenuListItem] = useState(extraMenuList);

    return (
        <section className={scrollHeight <= viewportHeight/pageHeroHeight ? "c-extra" : "c-extra--minimized"}>
            <ul className="o-extra-menu">
                {
                    extraMenuListItem &&
                        extraMenuListItem.map((extraMenuListItem, index) => (
                            <li key={"extraMenuList-"+extraMenuListItem.title} className="o-extra-menu-item">
                                <Link to={extraMenuListItem.url}>{extraMenuListItem.title}</Link>
                            </li>
                        )
                    )
                }
            </ul>
        </section>
    )
}