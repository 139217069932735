import { menuList } from './navbar/menuList';
import { MenuItems } from './navbar/MenuItems';

export const Navbar = ({ isOpen, pageHeroHeight, setOpen, scrollHeight, viewportHeight }) => {
    return (
        <nav className={scrollHeight <= viewportHeight/pageHeroHeight ? "c-header-navbar" : "c-header-navbar--minimized"}>
            <ul className={isOpen ? "o-navbar--open" : "o-navbar"}>
                {menuList.map((menu, index) => {
                    const depthLevel = 0

                    return (
                        <MenuItems
                            items={menu}
                            key={index}
                            depthLevel={depthLevel}
                            isOpen={isOpen}
                            setOpen={setOpen}
                        />
                    )
                })}
            </ul>
        </nav>
    );
};