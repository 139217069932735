import { Sling as Hamburger } from "hamburger-react"
import { Link } from "react-router-dom";

import logo from "./assets/svg/logo.svg";

import { Navbar } from "./components/Navbar";
import { Extra } from "./components/Extra";
import { lazy } from "react";

export const Header = ({ isOpen, pageName, scrollHeight, setOpen, viewportHeight,  }) => {
    pageName !== "contacts" ? pageName = "all" : window[pageName] = pageName

    const pageHeroHeight = {
        all: 12, // Used to be 1.5
        contacts: 12 
    }

    return(
        <header className={`c-header o-mobile-menu--${isOpen ? "open" : "closed"}  u-flex`}>
            <section className="c-hamburger-container">
                <section className="c-hamburger">
                    <Hamburger toggled={isOpen} toggle={setOpen} size={48} distance="lg" color="#0C0C5C" rounded label="Mostra il menu"
                     onToggle={toggled => {
                        if (toggled) {
                            document.body.classList.add("u-overflow-hidden")
                        } else {
                            document.body.classList.remove("u-overflow-hidden")
                        }
                    }} />
                </section>
            </section>

            {!isOpen &&
                <section className={scrollHeight <= viewportHeight/pageHeroHeight[pageName] ? "c-header-logo" : "c-header-logo--hidden"}>
                    <Link to="/"><img src={logo} alt="Logo Comunian e Zanetti SNC" loading="lazy" title="Logo Comunian e Zanetti SNC" className="c-logo" />
                    </Link>
                </section>
            }
            
            {!isOpen && <div className="u-flex-filler"></div>}

            <Navbar isOpen={isOpen} pageHeroHeight={pageHeroHeight[pageName]} setOpen={setOpen} scrollHeight={scrollHeight} viewportHeight={viewportHeight} />
            
		    <div className={isOpen ? "u-mobile-filler" : "u-flex-filler"}></div>

            <Extra isOpen={isOpen} pageHeroHeight={pageHeroHeight[pageName]} setOpen={setOpen} scrollHeight={scrollHeight} viewportHeight={viewportHeight} />

            {!isOpen && <Link to="/contacts" className={pageName !== "contacts" ? "c-contacts-menu" : "c-contacts-menu--hidden"}>Contatti</Link>}
        </header>
    );
};