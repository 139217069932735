import { SEO } from "../../../SEO";

import { PageHero } from "../PageHero";

export const Gestionepatrimonio = ({ pageName }) => {
    return (
        <>
            <SEO
                title="Comunian & Zanetti - Gestione del patrimonio"
                description="Il patrimonio personale accumulato nel corso della propria vita può essere trasferito ai propri figli, familiari e persone care in vario modo, ecco perché pianificare la successione è fondamentale."
            />

            <PageHero pageName={pageName} pageTitle="gestione del patrimonio" />

            <section className="o-page-content u-flex u-flex-col">
                <section className="o-content-paragraph u-flex u-flex-col">
                    <h2>Pianificazione successione familiare</h2>

                    <ul className="o-list">
                        <li>Il patrimonio personale accumulato nel corso della propria vita può essere trasferito ai propri figli, familiari e persone care in vario modo, ecco perché pianificare la successione è fondamentale.</li>
                        <li>Pianificheremo insieme a te la successione del patrimonio familiare definendo linee guida di una disposizione testamentaria personalizzata, cercando di ridurre o evitare le imposte attraverso l'analisi della posizione delle franchigie in essere e l'eventuale utilizzo di strumenti esenti dal punto di vista fiscale.</li>
                        <li>Obiettivo dell'analisi è di destinare il patrimonio alle persone care nel rispetto delle quote di legittima in moda da evitare <strong>FUTURE LITI FAMILIARI!!</strong></li>
                        <br />
                    </ul>

                    <p>Le azioni consigliate per raggiungere tale obiettivo saranno esaminate considerando gli aspetti legali, fiscali e finanziari al fine di disporre di una visione completa dell'impatto di tali proposte nel proprio processo di pianificazione familiare.<br/></p>

                    <ul className="o-list">
                        <li>Protezione dei disabili </li>
                    </ul>

                    <h2>Passaggio generazionale aziendale </h2>

                    <p>L'imprenditore deve conoscere gli aspetti giuridici, fiscali e finanziarie che possono essere utilizzati nel difficile processo legato al passaggio generazionale della propria azienda, poiché senza una pianificazione preventiva il passaggio aziendale potrebbe risultare talmente traumatico da comportare in breve tempo il naufragio di ciò che si è costruito con il lavoro di una vita.<br/><br/>
                    Identificheremo il passaggio generazionale dell'azienda identificando le soluzioni più adatte alle specifiche esigenze.<br/>
                    Di seguito alcune tipologie di strumenti che potranno essere analizzati:</p>
                    
                    <ul className="o-list">
                        <li>Donazione in vita oppure cessione in vita tramite atto pubblico dei diritti della proprietà oppure di parti di essi; </li>
                        <li>Successione testamentaria </li>
                        <li>Patto di famiglia </li>
                        <li>Trust</li>
                        <li>Strumenti di governance societaria </li>
                        <li>Holding familiare </li>
                    </ul>
                </section>
            </section>
        </>
    )
}