import { SEO } from "../../../SEO";

import { PageHero } from "../PageHero";

export const Tfmtfr = ({ pageName }) => {
    return (
        <>
            <SEO
                title="Comunian & Zanetti - TFM e TFR"
                description="TFM - Trattamento Fine Mandato è un prodotto rivolto alle piccole e medie imprese che intendono accantonare, attraverso un contratto di assicurazione, l'indennità di fine mandato dei propri soci, amministratori, collaboratori, collaboratori a progetto, beneficiando così di significative agevolazioni fiscali. TFR - Esso matura durante lo svolgimento del rapporto con il lavoratore ed è costituito dalla somma di accantonamenti annui di una quota di retribuzione rivalutata periodicamente."
            />

            <PageHero pageName={pageName} pageTitle="polizze tfm - tfr" />

            <section className="o-page-content u-flex u-flex-col">
                <section className="o-content-paragraph u-flex u-flex-col">
                    <p>Cosa sono le Polizze <strong>TFM</strong>?<br/>
                    <strong>TFM</strong> - Trattamento Fine Mandato è un prodotto rivolto alle piccole e medie imprese che intendono accantonare, attraverso un contratto di assicurazione, l'indennità di fine mandato dei propri soci, amministratori, collaboratori, collaboratori a progetto, <strong>beneficiando così di significative agevolazioni fiscali.</strong><br/><br/>
                    Come funzionano le polizze <strong>TFR</strong>?<br/><br/>
                    <strong>Le caratteristiche:</strong><br/>
                    <ol className="o-list">
                        <li>Il datore di lavoro versa la quota maturata di <strong>TFR</strong>; </li>
                        <li>La compagnia assicuratrice investe i relativi premi per liquidare alle scadenze la somma sufficiente a garantire il rispetto degli obblighi di legge da parte del datore di lavoro. </li>
                        <li>La polizza dà all'imprenditore la certezza di liquidare ai dipendenti il <strong>TFR</strong> al momento della cessazione del rapporto di lavoro. Il rischio infatti è che il <strong>TFR</strong> venga usato spesso come forma di autofinanziamento, e che non si abbia la liquidità necessaria al momento del bisogno. </li>
                    </ol> 
                    </p>
                </section>
            </section>
        </>
    )
}