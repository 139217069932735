import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import { SEO } from "./SEO";

import { Header } from "./Header";
import { Footer } from "./Footer";
import { BackToTop } from "./components/BackToTop";

import { Home } from "./components/Home";

import { Mobilita } from "./components/pages/privati/Mobilita";
import { Immobili } from "./components/pages/privati/Immobili";
import { Professionisti } from "./components/pages/privati/Professionisti";
import { Protezione } from "./components/pages/privati/Protezione";
import { Risparmio } from "./components/pages/privati/Risparmio";

import { Pmi } from "./components/pages/business/Pmi";
import { Industria } from "./components/pages/business/Industria";
import { Alberghi } from "./components/pages/business/Alberghi";
import { Rcprodotti } from "./components/pages/business/Rcprodotti";
import { Cyberrisk } from "./components/pages/business/Cyberrisk";
import { Deo } from "./components/pages/business/Deo";

import { Impresaedile } from "./components/pages/settorecostruzioni/Impresaedile";
import { Polizzacar } from "./components/pages/settorecostruzioni/Polizzacar";
import { Polizzapostuma } from "./components/pages/settorecostruzioni/Polizzapostuma";
import { Cauzione } from "./components/pages/settorecostruzioni/Cauzione";

import { Tfmtfr } from "./components/pages/welfare/Tfmtfr";
import { Welfare } from "./components/pages/welfare/Welfare";

import { Tutelalegale } from "./components/pages/tutelalegale/Tutelalegale";
import { Contacts } from "./components/pages/Contacts";

import { Bluassistance } from "./components/pages/bluassistance/Bluassistance";
import { Noleggiolungo } from "./components/pages/noleggiolungo/Noleggiolungo";
import { Gestionepatrimonio } from "./components/pages/gestionepatrimonio/Gestionepatrimonio";
import { Servizibancari } from "./components/pages/servizibancari/Servizibancari";

import { Reclami } from "./components/pages/Reclami"

import { PageNotFound } from "./components/pages/PageNotFound";

function App() {
  let initialViewportHeight = window.innerHeight

  window.addEventListener("resize", (e) => {
    let height = window.innerHeight
    setViewportHeight(height)
  })

  const [scrollHeight, setScrollHeight] = useState(0),
        [viewportHeight, setViewportHeight] = useState(initialViewportHeight),
        [isOpen, setOpen] = useState(false)

  useEffect(()=>{
    let lastVal = 0

    window.onscroll = function(){
        let y = window.scrollY
        y === 0 ? setScrollHeight(0) : setScrollHeight(y)
        window[lastVal] = y
    }

    window.scrollTo(0,0)
  }, []);
  
  let location = useLocation(),
      pageName = location.pathname.replace("/","");

  useEffect(() => {
    pageName === "" ? document.body.classList.add("o-home-variant") : document.body.classList.remove("o-home-variant")
  }, [pageName])

  useEffect(() => {
    window.scrollTo(0,0)
    isOpen && setOpen(false)
    document.body.classList.remove("u-overflow-hidden")
  }, [pageName]) // eslint-disable-line react-hooks/exhaustive-deps
  
  
  return (
    <>
      <SEO
        title="Comunian & Zanetti - Assicuratori SNC"
        description="L'agenzia Comunian e Zanetti Assicuratori SNC è sinonimo di sicurezza. Soluzioni personalizzate per ogni necessità di assicurazione: personale, professionale e aziendale."
      />

      <span id="scrollTop"></span>
      <Header isOpen={isOpen} pageName={pageName} scrollHeight={scrollHeight} setOpen={setOpen} viewportHeight={viewportHeight} />
      <main className={`c-content o-modal--${isOpen ? "open" : "closed"} u-flex u-flex-col`}>
        <Routes>
            <Route path="/" element={<Home />} />

            <Route path="/mobilita" element={<Mobilita pageName={pageName} />} />
            <Route path="/immobili" element={<Immobili pageName={pageName} />} />
            <Route path="/professionisti" element={<Professionisti pageName={pageName} />} />
            <Route path="/protezione" element={<Protezione pageName={pageName} />} />
            <Route path="/risparmio" element={<Risparmio pageName={pageName} />} />

            <Route path="/pmi" element={<Pmi pageName={pageName} />} />
            <Route path="/industria" element={<Industria pageName={pageName} />} />
            <Route path="/alberghi" element={<Alberghi pageName={pageName} />} />
            <Route path="/rcprodotti" element={<Rcprodotti pageName={pageName} />} />
            <Route path="/cyberrisk" element={<Cyberrisk pageName={pageName} />} />
            <Route path="/deo" element={<Deo pageName={pageName} />} />

            <Route path="/impresaedile" element={<Impresaedile pageName={pageName} />} />
            <Route path="/polizzacar" element={<Polizzacar pageName={pageName} />} />
            <Route path="/polizzapostuma" element={<Polizzapostuma pageName={pageName} />} />
            <Route path="/cauzione" element={<Cauzione pageName={pageName} />} />

            <Route path="/tfmtfr" element={<Tfmtfr pageName={pageName} />} />
            <Route path="/welfare" element={<Welfare pageName={pageName} />} />

            <Route path="/tutelalegale" element={<Tutelalegale pageName={pageName} />} />
            <Route path="/contacts" element={<Contacts pageName={pageName} scrollHeight={scrollHeight} viewportHeight={viewportHeight} />} />

            <Route path="/bluassistance" element={<Bluassistance pageName={pageName} />} />
            <Route path="/noleggiolungo" element={<Noleggiolungo pageName={pageName} />} />
            <Route path="/gestionepatrimonio" element={<Gestionepatrimonio pageName={pageName} />} />
            <Route path="/servizibancari" element={<Servizibancari pageName={pageName} />} />

            <Route path="/reclami" element={<Reclami pageName={pageName} />} />

            <Route path="*" element={<PageNotFound pageName={pageName} />} />
            
            {/* <Route path="/vantaggipmi" element={<Vantaggipmi pageName={pageName} />} /> */}
        </Routes>
      </main>
      <Footer pageName={pageName} />
      {!isOpen && <BackToTop scrollHeight={scrollHeight} />}
    </>
);
}

export default App;
