import { Link } from "react-router-dom";

import { PageHero } from "./PageHero";

export const PageNotFound = ({ pageName }) => {
    return (
        <>
            <PageHero pageName={pageName} pageTitle="404" />
            
            <section className="o-page-content u-flex u-flex-col"> 
                <section className="o-content-paragraph u-centered-text">
                    <h1>Pagina non trovata</h1>
                    
                    <p><i>Ci dispiace ma non esiste alcun collegamento al corrente indirizzo...</i><br /><br />
                    La preghiamo di tornare alla <Link to="/">Home</Link> o selezionare una pagina dai menu contestuali.</p>
                </section>
            </section>
        </>
    )
}