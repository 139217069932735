import { SEO } from "../../../SEO";

import { PageHero } from "../PageHero";

export const Polizzacar = ({ pageName }) => {
    return (
        <>
            <SEO
                title="Comunian & Zanetti - Polizza CAR"
                description="La Copertura Assicurativa CAR (Contractor's All Risks) è un contratto assicurativo che copre l'Assicurato per i danni materiali e diretti che colpiscano tutto ciò che è assicurato e posto nel luogo indicato in polizza durante il periodo di assicurazione. In breve assicura tutti i rischi del costruttore."
            />

            <PageHero pageName={pageName} pageTitle="polizza car" />

            <section className="o-page-content u-flex u-flex-col">
                <section className="o-content-paragraph u-flex u-flex-col">
                    <p>La <strong>Copertura Assicurativa CAR</strong> (Contractor's All Risks) è un contratto assicurativo che copre l'Assicurato per i danni materiali e diretti che colpiscano tutto ciò che è assicurato e posto nel luogo indicato in polizza durante il periodo di assicurazione. In breve assicura "tutti i rischi del costruttore".</p>
                </section>
            </section>
        </>
    )
}