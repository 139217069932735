import { MenuItems } from './MenuItems';

export const DropdownMenu = ({ depthLevel, dropdown, isOpen, setOpen, submenus, }) => {
    depthLevel = depthLevel +1
    const dropdownClass = depthLevel > 1 ? "o-dropdown-submenu" : ""

    return (
        <ul
            className={`o-dropdown-menu ${dropdownClass} ${
            dropdown ? 'show' : ''
            }`}
        >
            {submenus.map((submenu, index) => (
                <MenuItems
                    items={submenu}
                    key={index}
                    depthLevel={depthLevel}
                    setOpen={setOpen}
                    isOpen={isOpen}
                />
            ))}
        </ul>
    );
}