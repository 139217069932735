import { SEO } from "../../SEO";

import { PageHero } from './PageHero';

export const Reclami = ({ pageName }) => {
    return (
        <>
            <SEO
                title="Comunian & Zanetti - Reclami"
                description="Per ogni eventuale reclamo, contattaci agli indirizzi presenti in questa pagina."
            />

            <PageHero pageName={pageName} pageTitle="Reclami" />

            <section className="o-page-content u-flex u-flex-col">
                <section className="o-content-paragraph u-flex u-flex-col">
                    {/* <h2>Comunian e Zanetti Assicurazione</h2> */}
                    <p>Ai sensi dell'articolo 79 (sito internet) del Regolamento IVASS n. 40 del 2 agosto 2018 recante disposizioni in materia di distribuzione assicurativa e riassicurativa di cui al titolo IX del decreto legislativo 7 settembre 2005, n. 209 - Codice delle Assicurazioni private si riportano di seguito le seguenti informazioni:</p>
                    
                    <p><strong>Comunian e Zanetti Assicuratori S.n.c</strong><br />
                    <strong>Intermediario soggetto alla vigilanza dell'IVASS</strong></p>

                    <ul className="o-list--complaints">
                        <li>Ufficio: Strada Battaglia, 129 - 35020 Albignasego (PD)</li>
                        <li>Tel: <a href="tel:+0498808329">049 - 8808329</a></li>
                        <li>Partita IVA: <a href="https://telematici.agenziaentrate.gov.it/VerificaPIVA/Scegli.do?parameter=verificaPiva">972346587234</a></li>
                        <li>Mail: <a href="mailto:info@comunianezanetti.it">info@comunianezanetti.it</a></li>
                        <li>Reclami: <a href="mailto:info@comunianezanetti.it">info@comunianezanetti.it</a></li>
                        <li>PEC: <a href="mailto:comunianezanettisnc@legalmail.it">comunianezanettisnc@legalmail.it</a></li>
                        <li>Numero RUI: <a href="https://servizi.ivass.it/RuirPubblica/">A000656299</a></li>
                        <li>Sito internet: <a href="http://www.comunianezanetti.it/">www.comunianezanetti.it</a></li>
                    </ul>

                    <p><br/><strong>Uca Assicurazione Spese Legali e Peritali Spa</strong><br/></p>

                    <ul className="o-list--complaints">
                        <li>Ufficio Reclami: P.zza San Carlo, 161 - 10123 Palazzo Villa (TO)</li>
                        <li>Tel: <a href="tel:+0110920641">011 - 0920641</a></li>
                        <li>Fax: <a href="tel:+01119835740">011 - 19835740</a></li>
                        <li>Mail: <a href="mailto:uca@ucaspa.com">uca@ucaspa.com</a></li>
                        <li>Reclami: <a href="mailto:reclami@ucaspa.com">reclami@ucaspa.com</a></li>
                        <li>PEC: <a href="mailto:reclamiuca@legalmail.it">reclamiuca@legalmail.it</a></li>
                        <li>Sito internet: <a href="https://www.ucaspa.com/index.php">www.ucaspa.com</a></li>
                        <li>Link: <a href="https://www.ucaspa.com/pages.php?id=377">reclami uca</a></li>
                    </ul>

                    <p><br/><strong>Italiana Assicurazioni</strong><br/></p>

                    <ul className="o-list--complaints">
                        <li>Ufficio: Via Marco Ulpio Traiano, 18 - 20149 Milano (MI)</li>
                        <li>Tel: <a href="tel:+02397161">02 - 397161</a></li>
                        <li>Numero verde: <a href="tel:+800101313">800 - 101313</a></li>
                        <li>Fax: <a href="tel:+0239717001">02 - 39717001</a></li>
                        <li>Partita IVA: <a href="https://telematici.agenziaentrate.gov.it/VerificaPIVA/Scegli.do?parameter=verificaPiva">11998320011</a></li>
                        <li>Mail: <a href="mailto:benvenutinitaliana@italiana.it">benvenutinitaliana@italiana.it</a></li>
                        <li>Reclami: <a href="mailto:benvenutinitaliana@italiana.it">benvenutinitaliana@italiana.it</a></li>
                        <li>PEC: <a href="mailto:italiana@pec.italiana.it">italiana@pec.italiana.it</a></li>
                        <li>Sito internet: <a href="https://www.italiana.it/">www.italiana.it</a></li>
                        <li>Link: <a href="https://www.italiana.it/reclami">reclami Italiana Assicurazioni</a></li>
                    </ul>

                    <p><br/><strong>MAPFRE WARRANTY S.p.A.</strong><br/></p>

                    <ul className="o-list--complaints">
                        <li>Ufficio: Strada Trossi, 66 - 13871 Verrone (BI)</li>
                        <li>Tel: <a href="tel:+0155829811">015 - 5829811</a></li>                        
                        <li>Fax: <a href="tel:+0152558156">015 - 2558156</a></li>
                        <li>Partita IVA: <a href="https://telematici.agenziaentrate.gov.it/VerificaPIVA/Scegli.do?parameter=verificaPiva">11998320011</a></li>
                        <li>Mail: <a href="mailto:info@mapfrewarranty.it">info@mapfrewarranty.it</a></li>
                        <li>Reclami: <a href="mailto:ufficio.reclami@mapfre.com">ufficio.reclami@mapfre.com</a></li>
                        <li>PEC: <a href="mailto:ufficio.reclami@cert.mapfrewarranty.it">ufficio.reclami@cert.mapfrewarranty.it</a></li>
                        <li>Sito internet: <a href="https://www.mapfre.it/">www.mapfre.it</a></li>
                        <li>Link: <a href="https://www.mapfre.it/reclami-italy/">reclami MAPFRE WARRANTY S.p.A.</a></li>
                        <br/>
                        <li><b>MAPFRE ASISTENCIA S.A.</b></li>
                        <li>Ufficio: Strada Trossi, 66 - Verrone (BI)</li>
                        <li>Tel: <a href="tel:+0152558156">015 - 2558156</a></li>                                                
                        <li>Reclami: <a href="mailto:ufficio.reclami@mapfre.com">ufficio.reclami@mapfre.com</a></li>
                        <li>PEC: <a href="mailto:ufficio.reclami@cert.mapfreasistencia.it">mailto:ufficio.reclami@cert.mapfreasistencia.it</a></li>
                        <br/>                        
                        <li><b>Verti Assicurazioni S.p.A.</b></li>
                        <li>Ufficio: Via A. Volta, 16 - 20093 Cologno Monzese (MI)</li>
                        <li>Tel: <a href="tel:+0221092092">02 - 21092092</a></li>
                        <li>Fax: <a href="tel:+021725041">02 - 21725041</a></li>
                        <li>Reclami: <a href="mailto:reclami@verti.it">reclami@verti.it</a></li>                        
                    </ul>
                    
                    <p><br /><br />Autorità competente alla vigilanza dell'attività svolta<br/>
                    IVASS - Istituto per la Vigilanza sulle Assicurazioni - Via del Quirinale, 21 - 00187 Roma<br/>
                    Il contraente può avvalersi di altri eventuali sistemi di risoluzione stragiudiziale delle controversie previsti dalla normativa vigente.</p>

                    
                    {/* <h2>UCA</h2> */}
                    {/* <p>Parla in videoconferenza con il medico di cui hai bisogno OVUNQUE TI TROVI! Puoi scegliere il medico ed essere ricontattato secondo le tue disponibilità!</p> */}
                </section>
            </section>
        </>
    )
}