import React from "react"
import { Helmet } from "react-helmet"

export const SEO = ({ title, description, meta = [] }) => {
 return(
  <Helmet title = {title}
          htmlAttributes={{ lang: "it" }}
          meta={[
        {
          name: "description",
          content: description,
        },

        /* Facebook meta tags
        {
          property: "og:url",
          content: someUrl
        },
        {
          property: "og:type",
          content: someArticle
        },
        {
          property: "og:title",
          content: someTitle
        },
        {
          property: "og:description",
          content: someDescription
        },
        {
          property: "og:image",
          content: someImage
        },
        {
          property: "fb:app_id",
          content: someFbAppId
        },

        /* Twitter meta tags
        {
          property: "twitter:card",
          content: someSummary
        },
        {
          property: "twitter:creator",
          content: someAuthorName
        },
        {
          property: "twitter:title",
          content: someTitle
        },
        {
          property: "twitter:description",
          content: someDescription
        },
        {
          property: "twitter:image",
          content: someImage
        }*/
      ]}
   />
  )
}
