import { SEO } from "../../../SEO";

import { PageHero } from "../PageHero";

export const Cauzione = ({ pageName }) => {
    return (
        <>
            <SEO
                title="Comunian & Zanetti - Cauzione"
                description="La polizza è rivolta a tutti coloro che hanno necessità di prestare una cauzione a garanzia della regolare esecuzione di contratti di appalto, di fornitura o di servizi. Il vantaggio di rivolgersi al mercato assicurativo consiste nel minore costo rispetto alle banche, evitando l'utilizzo delle proprie linee di credito."
            />

            <PageHero pageName={pageName} pageTitle="cauzione (fidejussione)" />

            <section className="o-page-content u-flex u-flex-col">
                <section className="o-content-paragraph u-flex u-flex-col">
                    <p>La polizza è rivolta a tutti coloro che hanno necessità di <strong>prestare una cauzione</strong> a garanzia della regolare esecuzione di contratti di appalto, di fornitura o di servizi. Il vantaggio di rivolgersi al mercato assicurativo consiste nel <strong>minore costo</strong> rispetto alle banche, evitando l'utilizzo delle <strong>proprie linee di credito.</strong></p>
                </section>
            </section>
        </>
    )
}