export const extraMenuList = [
  {
    title: 'Blu assistance',
    url: '/bluassistance'
  },
  {
    title: 'Noleggio a Lungo Termine',
    url: '/noleggiolungo'
  },
  {
    title: 'Gestione del Patrimonio',
    url: '/gestionepatrimonio'
  },
  {
    title: 'Servizi Bancari',
    url: '/servizibancari',
  }
];