import React from 'react';
import { useState, useEffect } from "react";

import matteoBg from "../../assets/pic/matteo.jpg";
import lauraBg from "../../assets/pic/laura.jpg";
import andreaBg from "../../assets/pic/andrea.jpg";
import marikaBg from "../../assets/pic/marika.jpg";
import erikaBg from "../../assets/pic/erika.png";
import carloBg from "../../assets/pic/carlo.jpg";
import gianlucaBg from "../../assets/pic/gianluca.jpg";

import Swiper, { Pagination } from 'swiper';

export const Team = React.memo(() => {
    const teamItems = [
        {
            background: matteoBg,
            alt:"Fotografia di Matteo",
            orientation: "landscape",
            name: "Matteo Comunian",
            title: "Agente generale",
            quote: '"Il passato è la tua lezione. Il presente è il tuo dono. Il futuro è la tua motivazione"',
            payload: 'Ho avuto il privilegio di avviare la mia carriera sulle orme di due fuoriclasse del mestiere e della vita: Nonno Dante e Papà Franco. Dal 1963 nel segno della continuità, dell’innovazione e della costante ricerca del miglioramento.'
        },
        {
            background: andreaBg,
            alt:"Fotografia di Andrea",
            orientation: "landscape",
            name: "Andrea Zanetti",
            title: "Agente generale",
            quote: "Mai dimenticare da dove siamo venuti…",
            payload: null
            
        },
        {
            background: marikaBg,
            alt:"Fotografia di Marika",
            orientation: "portrait",
            name: "Marika Quaglio",
            title: "Resp. amm. & contabilità",
            quote: "Il tuo lavoro riempirà gran parte della tua vita e l’unico modo per essere veramente soddisfatto è fare quello che ritieni sia un ottimo lavoro. E l’unico modo per fare un ottimo lavoro è amare quello che fai.",
            payload: "Steve Jobs"
        },
        {
            background: lauraBg,
            alt:"Fotografia di Laura",
            orientation: "portrait",
            name: "Laura Babolin",
            title: "Resp. sinistri & front office",
            quote: "Puoi conoscere il cuore di un uomo già dal modo in cui egli tratta gli animali. Empatia, pazienza e competenza sono la chiave per affrontare ogni situazione con i clienti e nella vita.. e sono proprio i rapporti umani instaurati nel tempo che mi fanno amare il mio lavoro quasi quanto la mia Penny..",
            payload: null
        },
        {
            background: erikaBg,
            alt:"Fotografia di Erika",
            orientation: "portrait",
            name: "Erika Miozzo",
            title: "Impiegata front & back office",
            quote: "Le strade dritte non hanno mai prodotto piloti esperti.",
            payload: null
        },
        
        {
            background: carloBg,
            alt:"Fotografia di Carlo",
            orientation: "portrait",
            name: "Carlo Agricola",
            title: "Subagente",
            quote: "Scegli un lavoro che ami, e non dovrai lavorare neppure un giorno in vita tua.",
            payload: "Confucio"
        },
        {
            background: gianlucaBg,
            alt:"Fotografia di Gianluca",
            orientation: "portrait",
            name: "Gianluca Lazzaro",
            title: "Subagente",
            quote: "Non ti arrendere mai. Di solito è l’ultima chiave del mazzo quella che apre la porta.",
            payload: "Paulo Coelho"
        },
    ],
          [swiperTeamItem, setSwiperTeamItem] = useState(teamItems)

    // init Swiper:ddddd
    const teamSwiper = new Swiper('.o-team-swiper', {
        loop: true,
        modules: [ Pagination ],
        speed: 500,
        grabCursor: true,
        
        centeredSlides: true,
        breakpoints: {
            // when window width is >= 320px
            0: {
              slidesPerView: 1
            },
            // when window width is >= 480px
            1280: {
              slidesPerView: 3
            },
        },

        // Pagination bullets
        pagination: {
            el: '.swiper-pagination-bullets',
            clickable: true,
            dynamicBullets: true,
        }
    });

    useEffect(() => {
        teamSwiper.init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    return (
        <section className="c-team u-flex u-flex-col">
            <section className="o-section-title">
                <h1>IL NOSTRO TEAM</h1>
            </section>

            <div className="swiper o-team-swiper">

                <div className="swiper-wrapper o-team-swiper-wrapper">
                    {
                        swiperTeamItem &&
                            swiperTeamItem.map((swiperTeamItem, index) => (
                                <div key={"team-swiper-"+swiperTeamItem.background} className="swiper-slide o-team-slide u-flex">

                                    <section className="o-team-picture u-flex">
                                        <img src={swiperTeamItem.background} loading="lazy" alt={swiperTeamItem.alt} className={"o-pic--" + swiperTeamItem.orientation}></img>
                                    </section>
                                    <section className="u-flex u-flex-col">
                                        <h3>{swiperTeamItem.name}</h3>
                                        <h4>{swiperTeamItem.title}</h4>
                                        <p className="u-quote">{swiperTeamItem.quote}</p>
                                        <p>{swiperTeamItem.payload}</p>
                                    </section>

                                </div>
                            )
                        )
                    }
                </div>
                
                <div className="swiper-pagination swiper-pagination-bullets" />

            </div>
            
        </section>
    );
})