import { SEO } from "../../../SEO";

import { PageHero } from "../PageHero";

export const Risparmio = ({ pageName }) => {
    return (
        <>
            <SEO
                title="Comunian & Zanetti - Piano pensionistico e risparmio"
                description="Il vantaggio dei PIP rispetto ad altri strumenti della previdenza complementare è la loro IMPIGNORABILITÀ"
            />

            <PageHero pageName={pageName} pageTitle="risparmio" />
            
            <section className="o-page-content u-flex u-flex-col">
                <section className="o-content-paragraph u-flex u-flex-col">
                    <h2>PIANO INDIVIDUALE PENSIONISTICO</h2>
                    <p>L'adesione a un PIP consente di: 
                        <ul className="o-list">
                            <li>destinare parte dei tuoi risparmi per integrare la tua <strong>pensione di base</strong> e ricevere una pensione complementare, anche reversibile (in favore del coniuge o di altri beneficiari da te designati); </li>
                            <li>affrontare eventuali <strong>difficoltà personali e lavorative</strong> (ad esempio, spese sanitarie per te, il tuo coniuge e i tuoi figli, acquisto e ristrutturazione della prima casa di abitazione, anche dei tuoi figli, inoccupazione); </li>
                            <li>agevolare l'uscita dal mondo del lavoro e la transizione verso il pensionamento.</li>
                            <li>Il vantaggio dei <strong>PIP</strong> rispetto ad altri strumenti della previdenza complementare è la loro <strong>IMPIGNORABILITÀ</strong>.</li>    
                        </ul>
                    </p>

                    <h4>FLESSIBILIT&Agrave;</h4>
                    <p>Hai la possibilità di aumentare o diminuire gli importi dei versamenti oppure di effettuarne di aggiuntivi.</p>

                    <h4>TFR</h4>
                    <p>Puoi contribuire versando anche il trattamento di fine rapporto, nei casi previsti dalla Legge.</p>

                    <h4>FISCALIT&Agrave;</h4>
                    <p>Puoi dedurre fiscalmente dal tuo reddito gli importi versati, nei limiti consentiti dalla normativa vigente.</p>

                    <h2>INVESTIMENTI</h2>
                    <p>Del ramo vita fanno parte le <strong>polizze assicurative investimento</strong>, chiamate "contratti di capitalizzazione".<br/>Sono contratti di polizza che funzionano come strumenti finanziari: fanno "lavorare i soldi" tenendoli contemporaneamente al sicuro dall'inflazione e da altre forme di erosione monetaria.</p>

                    <h4>NO IMPOSTE DI SUCCESSIONE</h4>
                    <p>Le <strong>polizze</strong> vita non rientrano nell'asse ereditario e, come previsto delle leggi 2022, non andando in successione e non rientrando nell'asse ereditario, non concorrono alla formazione delle quote degli eredi e non sono soggette al pagamento dell'imposta di successione e di Irpef.</p>          
                           
                </section>
            </section>
        </>
    )
}