import { SEO } from "../../../SEO";

import { PageHero } from "../PageHero";

export const Bluassistance = ({ pageName }) => {
    return (
        <>
            <SEO
                title="Comunian & Zanetti - Prestazioni sanitarie blu assistance"
                description="La salute è il bene più prezioso per ognuno di noi. Per questo, da sempre, mettiamo al centro del nostro impegno quotidiano la salute delle persone e delle loro famiglie."
            />

            <PageHero pageName={pageName} pageTitle="prestazioni sanitarie - blu assistance" />

            <section className="o-page-content u-flex u-flex-col">
                <section className="o-content-paragraph u-flex u-flex-col">
                    <h2>Visite ed esami specialistici </h2>

                    <p>BASTA TEMPI D'ATTESA!<br />
                    Scegli, tra le Cliniche e i Poliambulatori del nostro network, dove eseguire accertamenti all'avanguardia e visite specialistiche nei tempi che desideri. </p>

                    <h2>Videoconsulto </h2>

                    <p>Parla in videoconferenza con il medico di cui hai bisogno OVUNQUE TI TROVI! Puoi scegliere il medico ed essere ricontattato secondo le tue disponibilità!</p>
                    
                    <h2>Fitness</h2>

                    <p>Sport e benessere: trova, in modo facile e veloce, le tue attività preferite tra quelle offerte dal circuito di centri fitness e scegli tra abbonamenti e singoli ingressi.</p>
                </section>
            </section>
        </>
    )
}