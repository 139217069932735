import { useState, useEffect, useRef } from "react";
import { DropdownMenu } from './DropdownMenu';

import { Link } from 'react-router-dom';

export const MenuItems = ({ depthLevel, isOpen, items, setOpen }) => {
  const [dropdown, setDropdown] = useState(false),
        flexibleBreakpoint = 1366

  let ref = useRef()

  useEffect(() => {
    const handler = (event) => {
      if (
        dropdown &&
        ref.current &&
        !ref.current.contains(event.target)
      ) {
        setDropdown(false)
      }
    };
    document.addEventListener('mousedown', handler)
    document.addEventListener('touchstart', handler)
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', handler)
      document.removeEventListener('touchstart', handler)
    };
  }, [dropdown])

  const onMouseEnter = () => {
    window.innerWidth > flexibleBreakpoint && setDropdown(true)
  }

  const onMouseLeave = () => {
    window.innerWidth > flexibleBreakpoint && setDropdown(false)
  }

  const closeDropdown = () => {
    dropdown && setDropdown(false)
  }

  return (
    <li
      className="o-navbar-item"
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={closeDropdown}
    >
      {items.url && items.submenu ? (
        <>
          <button
            className="o-menu-item"
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? 'true' : 'false'}
            onClick={() => setDropdown((prev) => !prev)}
          >
            {/*
            window.innerWidth < flexibleBreakpoint && depthLevel === 0 ? (
              items.title
            ) : (
              */
              <Link to={items.url} className={items.url === "/" && window.innerWidth > flexibleBreakpoint && "u-default-cursor"} onClick={(e) => items.url === "/" && e.preventDefault()}>{items.title}</Link>
            // )
            }
            {depthLevel > 0 &&
              window.innerWidth < flexibleBreakpoint ? null : depthLevel > 0 &&
              window.innerWidth > flexibleBreakpoint ? (
              <span>&raquo;</span>
            ) 
            : 
              null
            }
          </button>
          <DropdownMenu
            depthLevel={depthLevel}
            submenus={items.submenu}
            dropdown={dropdown}
          />
        </>
      ) : !items.url && items.submenu ? (
        <>
          <button
            className="o-menu-item"
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? 'true' : 'false'}
            onClick={() => setDropdown((prev) => !prev)}
          >
            {items.title}{' '}
            {depthLevel > 0 ? (
              <span>&raquo;</span>
            ) :
              null
            }
          </button>
          <DropdownMenu
            depthLevel={depthLevel}
            submenus={items.submenu}
            dropdown={dropdown}
          />
        </>
      ) : (
        <button
          className="o-menu-item"
          type="button"
          onClick={() => isOpen && setOpen(false)}
        >
          <Link to={items.url} className={items.url === "/" && "u-default-cursor"} onClick={(e) => items.url === "/" && e.preventDefault() }>{items.title}</Link>
        </button>
      )}
    </li>
  );
};