import partnerLogoItaliana from '../../assets/partners/italiana.png';
import partnerLogoGruppoper from '../../assets/partners/gruppoper.png';
import partnerLogoBlueAssistance from '../../assets/partners/blue-assistance.png';
import partnerLogoUca from '../../assets/partners/uca.png';
import partnerLogoMawdy from '../../assets/partners/mawdy.jpg';

import partnersBg from '../../assets/img/tobias-mrzyk-iuqmGmst5Po-unsplash.jpg';

export const Partners = () => {
    return (
        // <section className="c-partners u-flex u-flex-col" style={{ backgroundImage: `url(${partnersBg})` }}>
        <section className="c-partners u-flex u-flex-col">
            <section className="o-section-title">
                <h2>I NOSTRI PARTNERS</h2> 
            </section>

            <section className="o-partners-list u-flex">
                <div className="o-partners-row">
                    <div className="o-partner-logo">
                        <a href="https://www.italiana.it/">
                            <img src={partnerLogoItaliana} loading="lazy" alt="Partner Logo Italiana" title="Italiana" />
                        </a>
                    </div>
                    <div className="o-partner-logo">
                        <a href="https://www.blueassistance.it/BLUE/">
                            <img className="white" src={partnerLogoBlueAssistance} loading="lazy" alt="Partner Logo Blue Assistance" title="Blue Assistance" />
                        </a>
                    </div>
                </div>
                <div className="o-partners-row">
                    <div className="o-partner-logo">
                        <a href="https://www.ucaspa.com/">
                            <img src={partnerLogoUca} loading="lazy" alt="Partner Logo Uca" title="Uca" />
                        </a>
                    </div>
                    <div className="o-partner-logo">
                        <a href="https://www.gruppoper.com/">
                            <img src={partnerLogoGruppoper} loading="lazy" alt="Partner Logo Gruppoper" title="Gruppoper" />
                        </a>
                    </div>
                </div>
                <div className="o-partners-row">
                    <div className="o-partner-logo">
                        <a href="https://www.mawdy.it/">
                            <img src={partnerLogoMawdy} loading="lazy" alt="Partner Logo Mawdy" title="Mawdy" />
                        </a>
                    </div>                    
                </div>
            </section>
        </section>
    )
}