import { SEO } from "../../../SEO";

import { PageHero } from "../PageHero";

export const Pmi = ({ pageName }) => {
    return (
        <>
            <SEO
                title="Comunian & Zanetti - Piccole Medie Imprese"
                description="Possiamo analizzare tutti i rischi inerenti la tua attività offrendoti le migliori soluzioni assicurative presenti sul mercato per la tua azienda; tuteliamo le imprese artigiane, i commercianti e le piccole industrie, le aiutiamo a prosperare abbassando il più possibile i fattori di rischio."
            />

            <PageHero pageName={pageName} pageTitle="PMI" />
            
            <section className="o-page-content u-flex u-flex-col">
                <section className="o-content-paragraph u-flex u-flex-col">
                    <p>Possiamo analizzare tutti i rischi inerenti la tua attività offrendoti le migliori soluzioni assicurative presenti sul mercato per la tua azienda; tuteliamo le imprese artigiane, i commercianti e le piccole industrie, le aiutiamo a prosperare <strong>abbassando il più possibile i fattori di rischio.</strong>
                        <br/><br/>
                        Tutto quello che ti serve per lavorare in tranquillità!<br/><br/>
                        <ul className="o-list">
                             <li>Un pacchetto flessibile e modulabile con cui possiamo costruire la tua serenità a misura della tua azienda!</li>
                             <li>Finalmente avrai a disposizione un prodotto per coprire i costi in caso di danni e tutelare gli utili provenienti dalla tua attività d'impresa.</li>
                             <li>Furto e rapina;</li>
                             <li>Incendio ed esplosioni ed altri danni ai beni, compresi gli eventi atmosferici;</li>
                             <li>Tutti i rischi connessi alla responsabilità civile verso terzi e verso i dipendenti;</li>
                             <li>Tutela legale con rimborso delle spese legali per la difesa penale, controversie contrattuali e controversie in materia di lavoro e quelle relative a proprietà e locazioni.</li>
                             <li>Tutela mirata per le apparecchiature della tua azienda (garanzia elettronica) e per vetrate e insegne (garanzia cristalli).</li>
                        </ul>  
                    </p>
                    <h2>Vantaggi del prodotto per le PMI</h2>
                    <p>I vantaggi del prodotto:
                    <ul className="o-list">
                        <li>Pensato appositamente per le PMI</li>
                        <li>Conveniente e Flessibile.</li>
                        <li>Essendo un prodotto modulabile paghi in base alle reali esigenze che hai.</li>
                    </ul>  
                    <br/><br/>
                    Ti trovi all'interno di un centro commerciale? <br/>
                    Le nostre soluzioni prevedono specifiche coperture per meglio tutelarti in questi casi. <br/>
                    Ogni attività ha le sue necessità assicurative, puoi decidere liberamente di inserire solo le garanzie che ritieni siano essenziali per la tua attività: ottimizzi le coperture e risparmi sul prezzo! <br/><br/>
                    Insomma <strong>adattare</strong> la polizza alle tue esigenze non è mai stato <strong>così facile!</strong>
                    </p>
                </section>
            </section>
        </>
    )
}
                        
                        
                        